<!--

    if you want to edit this component to show different items:

        add a prop that would be the function to pull items by date,
        add a prop to disable the default filters 

-->


<template>
    <div style="min-width:1106px;background:white;position:relative;"
         v-bind:class="`planupCalendarDisplay_${displayType.value} ${showCrewMembers ? 'showCrewMembers' : ''}`">




        <div class="planupDayView"
             v-show="displayType.value == 'day'">





            <div class="cv-header">
                <div class="cv-header-nav">

                    <button aria-label="Previous Year"
                            @click="singleDayViewDate = new Date(singleDayViewDate.setMonth(singleDayViewDate.getMonth() - 1))"
                            class="previousYear"> &lt;&lt; </button>
                    <button aria-label="Previous Period"
                            style="background:red;"
                            @click="singleDayViewDate = new Date(singleDayViewDate.setDate(singleDayViewDate.getDate() - 1))"
                            class="previousPeriod">&lt;</button>
                    <button aria-label="Current Period"
                            @click="singleDayViewDate = new Date()"
                            class="currentPeriod"></button>
                    <button aria-label="Next Period"
                            @click="singleDayViewDate = new Date(singleDayViewDate.setDate(singleDayViewDate.getDate() + 1))"
                            class="nextPeriod"> &gt; </button>
                    <button aria-label="Next Year"
                            @click="singleDayViewDate = new Date(singleDayViewDate.setMonth(singleDayViewDate.getMonth() + 1))"
                            class="nextYear"> &gt;&gt; </button>


                </div>
                <div class="periodLabel">
                    {{ $globals.niceDate(singleDayViewDate) }}
                </div>






                <div style="position:absolute;top:8px;left:10px;">








                    <div style="display:block;float:left;margin-right:10px;position:relative;z-index:12;">
                        <Multiselect :options="planupCalendarDisplayTypes"
                                     class="whitebg_multiselect"
                                     style="width:70px;position:relative;top:-6px;display:inline-block"
                                     label="name"
                                     v-if="!hideDisplayTypeSelector"
                                     v-model="displayType"
                                     :allow-empty="false"
                                     deselectLabel=" "
                                     selectedLabel=" "
                                     selectLabel=" "
                                     placeholder=" "
                                     track-by="value">

                        </Multiselect>
                    </div>

                </div>

            </div>


            <div style="border:solid 1px #ddd">



                <table class="timeslotTable">

                    <tr @click="clickOnTimeSlot()"
                        class="clickableTimeSlot">
                        <td style="padding-top:15px;padding-bottom:15px;">
                            כל היום
                        </td>
                        <td style="text-align: right;">

                            <div :class="['timeSlotElement', singleDayItem.itemType]"
                                 v-for="singleDayItem in singleDayViewItems"
                                 @click="clickItem(singleDayItem)"
                                 v-if="!singleDayItem.time">

                                {{ singleDayItem.originalTitle }}


                            </div>

                            <div style="display:inline-block;height:31px;vertical-align: top;">
                                &nbsp;
                            </div>
                        </td>
                    </tr>


                    <tr v-for="timeslot in dayTimeslots"
                        @click="clickOnTimeSlot(timeslot)"
                        class="clickableTimeSlot">

                        <td>

                            {{ timeslot }}:00

                        </td>

                        <td style="text-align: right;">

                            <div :class="['timeSlotElement', singleDayItem.itemType]"
                                 @click="clickItem(singleDayItem)"
                                 v-for="(singleDayItem, index) in singleDayViewItems"
                                 v-if="singleDayItem.time && singleDayItem.time.substring(0, singleDayItem.time.indexOf(':')) == timeslot">

                                <span style="font-weight:bold">

                                    {{ singleDayItem.time }}

                                </span>

                                &nbsp;


                                {{ singleDayItem.originalTitle }}

                                <div :class="['timeSlotElementEndTime', singleDayItem.itemType]"
                                     :style="calculateTimeslotStyle(singleDayItem)">

                                </div>

                            </div>


                            <div style="display:inline-block;height:31px;vertical-align: top;">
                                &nbsp;
                            </div>


                        </td>

                    </tr>
                </table>



            </div>








        </div>







        <calendar-view locale="he"
                       v-show="displayType.value == 'month'"
                       :timeFormatOptions="{ hour: 'numeric', minute: 'numeric' }"
                       :display-period-uom="period"
                       :displayPeriodCount="periodCount"
                       :show-date="dateToShow"
                       :doEmitItemMouseEvents="true"
                       @item-mouseenter="showTooltip"
                       @item-mouseleave="hideTooltip"
                       :items="filteredItems"
                       :style="{ height }"
                       class="theme-default"
                       :class="{ planupCalendar_disableItemClicks: disableItemClicks }"
                       @click-item="clickItem"
                       @click-date="clickDate">


            <template #dayContent="h">
                <div v-html="$globals.hebrewDateDay(h.day)"
                     class="cv-date-heb"></div>
            </template>

            <template #header="{ headerProps }">
                <calendar-view-header :class="{ hideHeaderButtons: hideHeaderButtons }"
                                      :header-props="headerProps"
                                      @input="dateToShow = $event" />


                <div style="position:absolute;top:8px;left:10px;">



                    <div class="calendarItemTooltip"
                         ref="calendarItemTooltip">

                        <div class="topStrip">
                            {{ calendarItemTooltipType }}
                        </div>






                        <div class="calendarItemTooltipBody">




                            <div v-if="!calendarItemTooltipBody"
                                 style="text-align: center;">

                                <br>
                                <br>
                                <br>
                                <br>
                                <br>

                                <i class="fa-solid fa-spinner fa-spin"></i>


                            </div>
                            <div v-else
                                 v-html="calendarItemTooltipBody">
                            </div>


                        </div>






                        <br>
                        <br>
                        <br>



                    </div>

                    <label style="user-select: none;cursor:pointer"
                           @click="showGoogle = !showGoogle">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-red"
                              :class="{ checked: showGoogle }">
                            <i class="fa fa-check"></i>
                        </span>


                        יומן גוגל
                    </label>

                    &nbsp;

                    <label style="user-select: none;cursor:pointer"
                           @click="showEvents = !showEvents">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-purple"
                              :class="{ checked: showEvents }">
                            <i class="fa fa-check"></i>
                        </span>


                        אירועים סגורים
                    </label>

                    &nbsp;

                    <label style="user-select: none;cursor:pointer"
                           @click="showMaybeEvents = !showMaybeEvents">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-purple"
                              :class="{ checked: showMaybeEvents }">
                            <i class="fa fa-check"></i>
                        </span>


                        אירועים בפוטנציה
                    </label>




                    &nbsp;
                    &nbsp;

                    <label style="user-select: none;cursor:pointer"
                           @click="showMeetings = !showMeetings">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-green"
                              :class="{ checked: showMeetings }">
                            <i class="fa fa-check"></i>
                        </span>


                        פגישות
                    </label>



                    &nbsp;
                    &nbsp;

                    <label style="user-select: none;cursor:pointer"
                           @click="showTasks = !showTasks">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-yellow"
                              :class="{ checked: showTasks }">
                            <i class="fa fa-check"></i>
                        </span>


                        משימות
                    </label>


                    &nbsp;
                    &nbsp;

                    <label style="user-select: none;cursor:pointer"
                           @click="showHolidays = !showHolidays">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-realyellow"
                              :class="{ checked: showHolidays }">
                            <i class="fa fa-check"></i>
                        </span>


                        חגים
                    </label>






                    <div style="display:block;float:left;margin-right:10px;position:relative;z-index:12;">
                        <Multiselect :options="planupCalendarDisplayTypes"
                                     class="whitebg_multiselect"
                                     style="width:70px;position:relative;top:-6px;display:inline-block"
                                     label="name"
                                     v-if="!hideDisplayTypeSelector"
                                     v-model="displayType"
                                     :allow-empty="false"
                                     deselectLabel=" "
                                     selectedLabel=" "
                                     selectLabel=" "
                                     placeholder=" "
                                     track-by="value">

                        </Multiselect>
                    </div>




                    <div style='display:block;float:left;margin-right:10px;'>
                        <label class="switch">
                            <input type="checkbox"
                                   v-model="showCrewMembers"
                                   class="switch-input">
                            <span class="switch-label"
                                  data-on=""
                                  data-off=""></span>
                            <span class="switch-handle"></span>
                        </label>

                        <span style="position:relative;top: 6px;">
                            אנשי צוות
                        </span>
                    </div>




                </div>


            </template>



        </calendar-view>




        <Modal :hideFooter="true"
               ref="clickDateModal"
               :hideHeader="confirmDateOnClick"
               :title="clickDateModalTitle"
               :center="confirmDateOnClick">

            <template v-slot:body>







                <div v-if="confirmDateOnClick"
                     style="user-select:none;">


                    <div style="text-align: center;">

                        <h2 class="swal2-title">
                            {{ $globals.niceDate(clickedDate) }}
                        </h2>
                        <div class="swal2-content"
                             style="margin-bottom:30px;">
                            האם להמשיך עם התאריך הזה?
                        </div>




                        <button class="btn btn-primary"
                                @click="$emit('chooseDate', clickedDate)"
                                style="padding:10px 32px;font-size:17px"
                                data-bs-dismiss="modal">
                            המשך
                        </button>
                        &nbsp;
                        &nbsp;
                        <button class="btn btn-light"
                                style="padding:10px 32px;font-size:17px"
                                data-bs-dismiss="modal">
                            חזור
                        </button>


                    </div>


                </div>





                <div v-if="!confirmDateOnClick"
                     style="display:flex;justify-content: center;align-items:center;">












                    <div v-show="!chosenEntityToCreateInPopup"
                         class="card popupOptionBlock meeting"
                         @click="chosenEntityToCreateInPopup = 'meeting'">
                        <div>
                            <i class="fa-regular fa-handshake"></i>
                            <br>
                            {{ $t('setMeeting') }}
                        </div>
                    </div>

                    <div v-show="!chosenEntityToCreateInPopup"
                         class="card popupOptionBlock event"
                         @click="chosenEntityToCreateInPopup = 'event'">
                        <div>
                            <i class="fa-solid fa-champagne-glasses"
                               style="transform:scale(0.88)"></i>
                            <br>
                            {{ $t('createEvent') }}
                        </div>
                    </div>

                    <div v-show="!chosenEntityToCreateInPopup"
                         class="card popupOptionBlock task"
                         @click="chosenEntityToCreateInPopup = 'task'">
                        <div>
                            <i class="fa-solid fa-bullseye"
                               style="transform:scale(0.88)"></i>
                            <br>
                            {{ $t('createTask') }}
                        </div>
                    </div>



                    <div v-show="chosenEntityToCreateInPopup && chosenEntityToCreateInPopup != 'event'"
                         class="card popupOptionBlock"
                         :class="chosenEntityToCreateInPopup"
                         @click="emitNewEntityChoice('withoutCustomer')">
                        <div>
                            <i class="fa-solid fa-user-slash"
                               style="transform:scale(0.75)"></i>
                            <br>
                            {{ $t('withoutCustomer') }}
                        </div>
                    </div>


                    <div v-show="chosenEntityToCreateInPopup"
                         class="card popupOptionBlock"
                         :class="chosenEntityToCreateInPopup"
                         @click="emitNewEntityChoice('newCustomer')">
                        <div>
                            <i class="fa-solid fa-user-plus"
                               style="transform:scale(0.75)"></i>
                            <br>
                            {{ $t('newCustomer') }}
                        </div>
                    </div>

                    <div v-show="chosenEntityToCreateInPopup"
                         class="card popupOptionBlock"
                         :class="chosenEntityToCreateInPopup"
                         @click="emitNewEntityChoice('existingCustomer')">
                        <div>
                            <i class="fa-solid fa-user-group"
                               style="transform:scale(0.75)"></i>
                            <br>
                            {{ $t('existingCustomer') }}
                        </div>
                    </div>



















                </div>




                <div v-show="!showDailyAgenda && itemsToShowInPopup.length">
                    <span style="color:#ab8ce4;cursor:pointer;user-select:none;"
                          @click="showDailyAgenda = true">

                        הצג לו"ז ימי
                        &nbsp;
                        <i class="fa-solid fa-sort-down"
                           style="font-size:17px;position:relative;top:-2px;"></i>



                        <!-- <span onclick="planup.$swal({title: 'abc',text: 'def',showCancelButton: true,confirmButtonText: 'המשך',cancelButtonText: 'חזור',confirmButtonColor: '#ab8ce4',})">
test swal
                    </span> -->

                    </span>
                    <br>
                    <br>

                </div>






                <div v-if="showDailyAgenda && itemsToShowInPopup.length">


                    <span style="color:#ab8ce4;cursor:pointer;user-select:none;"
                          @click="showDailyAgenda = false">

                        הסתר לו"ז יומי
                        &nbsp;
                        <i class="fa-solid fa-sort-up"
                           style="font-size:17px;position:relative;top:6px;"></i>
                    </span>

                    <br>
                    <br>





                </div>
                <transition name="smooth">

                    <div v-show="showDailyAgenda"
                         :style="{ 'maxHeight': (itemsToShowInPopup.length * 40) + 'px' }">
                        <table style="width:100%;"
                               class="busyDayPopupTable">
                            <tr v-for="item in itemsToShowInPopup"
                                @click="clickItem(item)">

                                <td style="width:50px;color:#777;text-align: center;">
                                    {{ item.time ? item.time : 'כל היום' }}
                                </td>
                                <td style="text-align:center;;width:80px;color:white;padding:5px">
                                    <span :class="`clickDateModalRow_${item.itemType}`"
                                          style="display:inline-block;border-radius:6px;padding: 3px; width:100%;">
                                        {{ $t(item.itemType) }}
                                    </span>
                                </td>
                                <td style="padding-right: 5px;">
                                    {{ item.originalTitle ? item.originalTitle : 'ללא' }}
                                </td>


                            </tr>
                        </table>
                    </div>
                </transition>



                <br>
                <br>

                <span class="pull-right btn btn-light"
                      @click="setDayView">
                    מעבר לתצוגת יום
                </span>






            </template>


        </Modal>






    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import axiosV2 from "../../core/http/axios_api_v2";
import { debounce } from "../../helpers/interaction";

export default {
    name: "PlanupCalendar",
    props: {

        disableClickDatePopup: {
            default: false
        },


        hideDisplayTypeSelector: {
            default: false
        },

        confirmDateOnClick: {
            default: false,
        },
        showTodayAgendaOnPopup: {
            default: true
        },
        maxItemsInDay: {
            default: 3
        },
        disableItemClicks: {
            default: false,
        },
        period: {
            default: 'month'
        },
        periodCount: {
            default: 1
        },
        hideHeaderButtons: {
            default: false
        },
        height: {
            default: '770px'
        }
    },
    watch: {



        displayType() {

            if (!this.singleDayViewDate)
                this.singleDayViewDate = new Date();

        },

        singleDayViewDate(newVal) {

            this.singleDayViewItems = this.findItemsInThisDay(newVal);

        },


        chosenEntityToCreateInPopup(newVal) {

            if (newVal == 'task') {
                this.$refs.clickDateModal.hide();
                let task = this.$handlers.tasksHandler.generateLocalEmptyTask();
                task.date = this.clickedDate;
                this.$root.$emit('hideQuickCalendar');
                this.$root.$emit('openTaskModal', task);
            }
        },
        dateToShow(newVal) {

            this.updateItems(newVal);
        }
    },
    computed: {



        dayTimeslots() {

            let startTime = parseInt(this.$store?.state?.businessSettings?.workDayStart?.match(/(.*):/)[1]);
            let endTime = parseInt(this.$store?.state?.businessSettings?.workDayEnd?.match(/(.*):/)[1]);

            if (!startTime)
                startTime = 9;

            if (!endTime)
                endTime = 21;

            let timeslots = [];


            this.singleDayViewItems.forEach(item => {

                if (!item.time)
                    return;

                let hour = parseInt(item.time.match(/(.*):/)[1]);

                if (hour < startTime)
                    startTime = hour;

                if (hour > endTime)
                    endTime = hour;

            });


            for (let i = startTime; i < endTime; i++) {
                timeslots.push(i);
            }


            return timeslots;


        },


        filteredItems() {

            let filteredItems = deepCopy(this.items);

            if (!this.showEvents)
                filteredItems = filteredItems.filter(i => i.itemType != 'event');

            if (!this.showMaybeEvents)
                filteredItems = filteredItems.filter(i => i.itemType != 'maybeEvent');

            if (!this.showMeetings)
                filteredItems = filteredItems.filter(i => i.itemType != 'meeting');

            if (!this.showTasks)
                filteredItems = filteredItems.filter(i => i.itemType != 'task');


            if (!this.showGoogle)
                filteredItems = filteredItems.filter(i => i.itemType != 'googleEvent');




            if (this.showHolidays) {
                filteredItems = filteredItems.concat(this.$store.state.holidays);
            }

            filteredItems = filteredItems.sort(this.$globals.compareFunctionForSortCalendarItems)


            this.filterTooManyOneDay(filteredItems);



            return filteredItems;

        }

    },
    data() {


        let planupCalendarDisplayTypes = [
            { name: 'חודש', value: 'month' },
            { name: 'יום', value: 'day' }
        ];

        return {

            chosenTime: null,

            lastZIndexForTimeslot: 1,


            showCrewMembers: false,


            calendarItemTooltipType: ' ',
            calendarItemTooltipName: ' ',
            calendarItemTooltipDate: null,
            calendarItemTooltipBody: null,


            singleDayViewItems: [],


            singleDayViewDate: null,


            planupCalendarDisplayTypes,

            displayType: planupCalendarDisplayTypes[0],


            chosenEntityToCreateInPopup: null,


            clickedDate: null,

            showDailyAgenda: false,


            dateToShow: new Date(),
            itemsInBusyDays: [],

            clickDateModalTitle: '',
            itemsToShowInPopup: [],

            items: [],

            showEvents: true,
            showMeetings: true,
            showTasks: true,
            showHolidays: true,
            showMaybeEvents: true,
            showGoogle: true,

            currentCacheKey: this.$handlers.calendarHandler.buildCacheKey(new Date())


        }
    },
    // watch:{
    //     '$globals.calendarHandler.cache': {
    //         function(newVal){
    //         console.log('i am watching the cache and it changed')
    //     },
    //     deep:true
    //     }
    // },
    methods: {


        showTooltip(item, event) {


            event.target.setAttribute('title', '')
            this.calendarItemTooltipBody = null;

            this.$refs.calendarItemTooltip.style.display = 'block';
            this.$refs.calendarItemTooltip.style.left = event.clientX + 10 + 'px';
            this.$refs.calendarItemTooltip.style.top = event.clientY + 10 + 'px';

            this.calendarItemTooltipType = this.$t(item.originalItem.itemType);

            this.$refs.calendarItemTooltip.setAttribute('popup-type', `${item.originalItem.itemType}`);

            let itemId = item.originalItem?.id?.match(/_(\d+)/g)?.map(num => num.replace('_', ''));
            


            if (['googleEvent', 'holiday'].includes(item.originalItem.itemType)) {
                this.hideTooltip();
                return;
            }




            debounce(() => {

                switch (item.originalItem.itemType) {
                    case 'event':
                        this.$handlers.eventHandler.calendarTooltip(itemId).then(html => this.calendarItemTooltipBody = html)
                        break;
                    case 'meeting':
                        this.$handlers.meetingHandler.calendarTooltip(itemId).then(html => this.calendarItemTooltipBody = html)
                        break;
                    case 'task':
                        this.$handlers.tasksHandler.calendarTooltip(itemId).then(html => this.calendarItemTooltipBody = html)
                        break;
                        
                }

            }, 500)();








        },
        hideTooltip() {
            this.$refs.calendarItemTooltip.style.display = 'none';
        },




        calculateTimeslotStyle(item) {


            if (!item.endTime)
                return `height:12px;`;

            let startHour = +item.time.split(':')[0];
            let endHour = +item?.endTime?.split(':')[0];

            let height = ((endHour - startHour) * 40) - 28;

            return `height:${height}px;`;
            // return `height:${height}px;z-index:${this.lastZIndexForTimeslot++};`;


        },


        clickOnTimeSlot(time) {


            if (this.disableClickDatePopup) {
                this.$emit('chooseTime', time);
                return;
            }


            this.clickedDate = this.singleDayViewDate;



            this.itemsToShowInPopup = this.findItemsInThisDay(this.singleDayViewDate);
            this.showDailyAgenda = this.showTodayAgendaOnPopup;
            this.chosenEntityToCreateInPopup = null;
            this.$refs.clickDateModal.show();

            this.clickDateModalTitle = ' מה תרצו לעשות בתאריך ' + this.$globals.niceDate(this.singleDayViewDate) + ' בשעה ' + time + ':00?';


            this.chosenTime = time;
            this.$refs.clickDateModal.show();


        },


        setDayView() {

            this.displayType = this.planupCalendarDisplayTypes.find(pcdt => pcdt.value == 'day');

            if (this.clickedDate)
                this.singleDayViewDate = this.clickedDate;

            this.$refs.clickDateModal.hide()    //leave this line here
        },

        setMonthView() {

            this.displayType = this.planupCalendarDisplayTypes.find(pcdt => pcdt.value == 'month');
        },

        emitNewEntityChoice(customerChoice) {

            this.$refs.clickDateModal.hide();

            let time = null;
            if (this.chosenTime)
                time = this.chosenTime + ':00';

            switch (`${this.chosenEntityToCreateInPopup} ${customerChoice}`) {



                case 'event newCustomer':
                    this.$handlers.customerHandler.goToAddCustomerAddEvent(this.clickedDate, time);
                    break;

                case 'event existingCustomer':
                    this.$root.$emit('findCustomer', this.$handlers.eventHandler.gotoAddNewEventToCustomerFromSearchResult, this.clickedDate, time);
                    break;

                case 'meeting newCustomer':
                    this.$handlers.customerHandler.goToAddCustomerOnlyMeeting(this.clickedDate, time);
                    break;

                case 'meeting existingCustomer':
                    this.$root.$emit('findCustomer', this.$handlers.meetingHandler.gotoAddNewMeetingFromSearch, this.clickedDate, time);
                    break;

                case 'meeting withoutCustomer':
                    this.$globals.findComponent('App').openMeetingWithoutCustomerModal(this.clickedDate,null,time);
                    break;
            }

        },


        findItemsInThisDay(date) {

            let start = new Date(date.getTime());
            start.setHours(0, 0, 0, 0);
            let end = new Date(date.getTime());
            end.setHours(23, 59, 59, 999);

            let list = [];


            let allItems = deepCopy(this.items).concat(this.$store.state.holidays);
            allItems = allItems.sort(this.$globals.compareFunctionForSortCalendarItems);

            for (let i = 0; i < allItems.length; i++) {


                let d = new Date(allItems[i].startDate)

                if (d < start)
                    continue;

                if (d > end)
                    return list;



                list.push(allItems[i]);

            }

            return list;

        },



        clickDate(date) {

            this.clickedDate = date;


            if (this.disableClickDatePopup) {
                this.$emit('chooseDate', date);
                return;
            }



            this.itemsToShowInPopup = this.findItemsInThisDay(date);
            this.showDailyAgenda = this.showTodayAgendaOnPopup;
            this.chosenEntityToCreateInPopup = null;
            this.$refs.clickDateModal.show();


            //this line of clickDateModalTitle was before the option to also have a "do you want to continue with this date" in the same clickDateModal.
            //we use a prop to distinguish the two options
            this.clickDateModalTitle = ' מה תרצו לעשות בתאריך ' + this.$globals.niceDate(date) + '?';

        },

        clickItem(item) {

            let itemType = item.id.split('_')[0];
            let itemId = item.id.split('_')[1];

            switch (itemType) {
                case 'event':
                    this.$root.$emit('hideQuickCalendar');
                    this.$router.push(`/eventToCustomer/${itemId}`)
                    return;
                case 'meeting':
                    this.$root.$emit('hideQuickCalendar');
                    this.$router.push(`/meetingToCustomer/${itemId}`)
                    return;
                case 'task':
                    this.$root.$emit('hideQuickCalendar');
                    return this.$handlers.tasksHandler.getTask(itemId).then(task => this.$root.$emit('openTaskModal', task))
                case 'holiday':
                    console.log('hohohohoholiday');
                    break;
            }




            if (this.disableItemClicks) {
                return this.clickDate(item.startDate);
            } else {
                this.$swal(item.title);
            }





        },
        updateItems(date) {

            this.$handlers.calendarHandler.getItemsInMonth(date).then(items => {
                this.items = items;
                this.currentCacheKey = this.$handlers.calendarHandler.buildCacheKey(date);
            });
        },

        filterTooManyOneDay(filteredItems) {

            //initial count to see if we have too many in each slot
            this.itemsInBusyDays = [];
            for (let i = 0; i < filteredItems.length; i++) {

                let index = this.$globals.niceDate(filteredItems[i].startDate);

                if (!this.itemsInBusyDays[index])
                    this.itemsInBusyDays[index] = [];

                this.itemsInBusyDays[index].push(filteredItems[i]);

            }


            //going over the list we made
            for (const index in this.itemsInBusyDays) {


                //if we have more than 3 things in a day we work on it
                if (this.itemsInBusyDays[index].length > this.maxItemsInDay) {

                    //count how many to hide
                    let howManyHidden = this.itemsInBusyDays[index].length - this.maxItemsInDay;


                    //removing them
                    for (let i = 0; i < this.itemsInBusyDays[index].length; i++) {

                        if (i < this.maxItemsInDay) {

                            this.itemsInBusyDays[index][i].style = `top:calc(${(i + 1) * 1.4}em + ${i * 2}px)!important;`

                        } else {

                            filteredItems.splice(filteredItems.indexOf(this.itemsInBusyDays[index][i]), 1);
                        }

                    }


                    //adding the label to say we have more
                    filteredItems.push({
                        'id': index,
                        title: `ועוד ${howManyHidden}`,
                        itemType: 'showMore',
                        classes: ['calendarItemShowMore'],
                        'startDate': this.itemsInBusyDays[index][0].startDate,
                        style: `bottom: ${14 + (3 - this.maxItemsInDay) * 22}px`
                    });
                }
            }
        }
    },
    mounted() {
        this.$handlers.calendarHandler.registerCalendarComponent(this);
        this.updateItems(new Date());
    },

    components: { CalendarView, CalendarViewHeader, Multiselect }
}
</script>
<style>
.busyDayPopupTable tr:nth-of-type(odd) {
    background: rgba(51, 68, 109, .03);

}

.busyDayPopupTable tr:hover {
    cursor: pointer;

}

.busyDayPopupTable tr:hover span {
    font-weight: bold;

}

/* .busyDayPopupTable tr{
    border-bottom: solid 1px #eeeeee;
}

.busyDayPopupTable tr:first-child{
    border-top: solid 1px #eeeeee;
}
 */


.timeSlotElement {
    display: inline-block;
    position: relative;
    border-radius: 6px;
    margin: 5px;
    padding-left: 40px !important;
    padding-right: 10px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
}


.timeslotTable {
    width: 100%;
}

.timeslotTable tr td:first-child {
    width: 50px;
    text-align: center;
}


.timeslotTable td {
    padding: 5px;
}

.timeslotTable tr {
    border-bottom: solid 1px #ddd;
}



.timeslotTable .holiday {
    background-color: #fec107;
    padding: 5px;
    color: white;
    margin-bottom: 2px;
    cursor: pointer;
}

.timeslotTable .holiday:after {
    content: "\f69a";
    position: absolute;
    left: 10px;
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
}


.timeslotTable .event {
    background-color: #ddcff6;
    padding: 5px;
    color: white;
    margin-bottom: 2px;
    cursor: pointer;
}

.timeslotTable .event:after {
    content: "\f79f";
    position: absolute;
    left: 10px;
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
}


.timeslotTable .meeting {
    background-color: #66dabd;
    padding: 5px;
    color: white;
    margin-bottom: 2px;
    cursor: pointer;
}

.timeslotTable .meeting:after {
    content: "\f2b5";
    position: absolute;
    left: 10px;
    font-weight: 400;
    font-family: "Font Awesome 6 Free";
}


.timeslotTable .task {
    background-color: #87e4f0;
    padding: 5px;
    color: white;
    margin-bottom: 2px;
    cursor: pointer;
}


.timeslotTable .task:after {
    content: "\f140";
    position: absolute;
    left: 10px;
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
}




.timeslotTable .googleEvent {
    background-color: #ff5370 !important;
    padding: 5px;
    color: white;
    margin-bottom: 2px;
    cursor: pointer;
}

.timeslotTable .googleEvent:after {
    content: "\f1a0";
    position: absolute;
    left: 10px;
    font-weight: 400;
    font-family: "Font Awesome 6 Brands";
}


.planupCalendarDisplay_day {
    min-width: unset !important;
}


.timeSlotElementEndTime {
    position: absolute;
    left: 0;
    width: 100%;
    height: 200px;
    border-radius: 0 0 6px 6px;
}

.timeSlotElementEndTime::after {
    display: none !important;
}
</style>