import axiosV2, { apiPost } from '../core/http/axios_api_v2';

export default {



    getStatus(){
        
        return axiosV2('whatsapp/getStatus').then(res =>{

            return res?.data?.phone ? res.data.phone + ' מחובר' : 'לא מחובר';

        })

    },

    sendMessage(to,msg){

        if (!msg){
            msg = prompt('מה לשלוח?');
        }

        let formattedNumber = to.slice(0,2)=='05' ? to.slice(1) : to;

        apiPost(`whatsapp/sendMessage`,{to:formattedNumber,msg})

    },

    getQr(){
        return axiosV2('whatsapp/getQr').then(res =>{
            return res.data
        })
    },

    async getProfilePic(contact){

        let picUrl = await apiPost('whatsapp/getProfilePic',{contactId:contact.id});
        console.log(picUrl);
        contact.pic = picUrl;
        return picUrl;


    },

    disconnect(){
        return apiPost('whatsapp/disconnect')
    }


}