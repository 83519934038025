<template>
  <div class="page-sidebar custom-scrollbar original-position page-sidebar-open"
       style="overflow-x:visible;overflow-y: scroll;">

    <div class="sidebar-user text-center"
         style="padding-bottom:5px">
      <div>
        <img :src="$store.state.logoImageUrl"
             onerror="this.src='https://www.mit4mit.co.il/img/biz_placeHolder.png'"
             style="max-width:100%;min-width:130px;height:130px;border-radius:6px;">
        <br>
        <br>
      </div>
    </div>

    <ul class="sidebar-menu"
        style="padding-top:0">

      <li>
        <div class="sidebar-title"
             style="margin-top:15px;color:#4a4a4a;text-align: center;">

          {{ $store.state.businessSettings.bizName }}

        </div>
      </li>





      <li v-if="$store.getters.getUser.type == 1">

        <router-link to="users/add">
          <button class="btn btn-primary">
            הוסף משתמש

          </button>
        </router-link>
            </li>





      <li>
        <div style="position:relative">
          <div
            id="sidemMenuAddNewMenu"
            style="width:150px;"
               class="addNewMenu card popupMenuToHideOrNot">


            <div v-if="false && $route.name == 'CustomerPage'">

              <div class="addNewMenu_Item"
                   @click="$root.$emit('addEventToThisCustomer')">
                <span style="width:15px;text-align: center;display:inline-block">

                  <i class="fa-regular fa-calendar"></i>
                </span>
                &nbsp;
                הוספת אירוע
                <span style="float:left;color:rgb(119,119,119);transform-origin:center;transform:scale(0.9)">
                  לקוח
                  {{ $router.currentRoute.params.id }}
                </span>
              </div>

              <div class="addNewMenu_Item"
                   @click="$root.$emit('addMeetingToThisCustomer')">
                <span style="width:15px;text-align: center;display:inline-block">

                  <i class="fa-regular fa-handshake"></i>
                </span>
                &nbsp;
                הוספת פגישה
                <span style="float:left;color:rgb(119,119,119);transform-origin:center;transform:scale(0.9)">
                  לקוח
                  {{ $router.currentRoute.params.id }}
                </span>
              </div>

              <div class="addNewMenu_Item"
                   @click="$root.$emit('addTaskToThisCustomer')">
                <span style="width:15px;text-align: center;display:inline-block">
                  <i class="fa-solid fa-bullseye"></i>
                </span>
                &nbsp;
                הוספת משימה
                <span style="float:left;color:rgb(119,119,119);transform-origin:center;transform:scale(0.9)">
                  לקוח
                  {{ $router.currentRoute.params.id }}
                </span>
              </div>




              <div style="border-bottom: solid 1px #d5d5d5;margin-bottom:1px;">

              </div>
            </div>





            <router-link to="/customer"
                         class="addNewMenu_Item">

              <span style="width:15px;text-align: center;display:inline-block">
                <i class="fa-regular fa-user"></i>
              </span>

              &nbsp;
              הוספת לקוח




            </router-link>

            <div v-if="false"
                 @click="$handlers.customerHandler.goToAddCustomerOnlyContacts()"
                 class="addNewMenu_Item">
              <span style="width:15px;text-align: center;display:inline-block">
                <i class="fa-regular fa-user"></i>
              </span>

              &nbsp;
              הוספת לקוח
              (ישן)



            </div>




            <div class="addNewMenu_Item"
                 @click="$root.$emit('openTargetForNewEntityModal', 'event')">
              <span style="width:15px;text-align: center;display:inline-block">

                <i class="fa-regular fa-calendar"></i>
              </span>
              &nbsp;
              הוספת אירוע
            </div>

            <div class="addNewMenu_Item"
                 @click="$root.$emit('openTargetForNewEntityModal', 'meeting')">
              <span style="width:15px;text-align: center;display:inline-block">

                <i class="fa-regular fa-handshake"></i>
              </span>
              &nbsp;
              הוספת פגישה
            </div>


            <div v-if="false"
                 class="addNewMenu_Item">
              <span style="width:15px;text-align: center;display:inline-block">

                <i class="fa-regular fa-calendar"></i>
              </span>
              &nbsp;
              הוספת אירוע

              <i class="fa fa-angle-left"
                 style="float:left;position:relative;top:4px;"></i>

              <div class="addNewMenu_subMenu card">
                <div @click="$handlers.customerHandler.goToAddCustomerAddEvent()"
                     class="addNewMenu_Item">
                  <i class="fa-solid fa-user-plus"></i>
                  &nbsp;
                  לקוח חדש
                </div>
                <div class="addNewMenu_Item"
                     @click="$root.$emit('findCustomer', $handlers.eventHandler.gotoAddNewEventToCustomerFromSearchResult)">
                  <i class="fa-solid fa-user-group"></i>
                  &nbsp;
                  לקוח קיים
                </div>

              </div>
            </div>








            <div v-if="false"
                 class="addNewMenu_Item">
              <span style="width:15px;text-align: center;display:inline-block">

                <i class="fa-regular fa-handshake"></i>
              </span>
              &nbsp;
              הוספת פגישה
              <i class="fa fa-angle-left"
                 style="float:left;position:relative;top:4px;"></i>

              <div class="addNewMenu_subMenu card">
                <div @click="$handlers.customerHandler.goToAddCustomerOnlyMeeting()"
                     class="addNewMenu_Item">
                  <i class="fa-solid fa-user-plus"></i>
                  &nbsp;
                  לקוח חדש
                </div>
                <div class="addNewMenu_Item"
                     @click="$root.$emit('findCustomer', $handlers.meetingHandler.gotoAddNewMeetingFromSearch)">
                  <i class="fa-solid fa-user-group"></i>
                  &nbsp;
                  לקוח קיים
                </div>

              </div>

            </div>


            <div @click="$root.$emit('openTargetForNewEntityModal', 'task')"
                         class="addNewMenu_Item">
              <span style="width:15px;text-align: center;display:inline-block">
                <i class="fa-solid fa-bullseye"></i>
              </span>
              &nbsp;
              הוספת משימה
            </div>

            <router-link v-if="false"
                         to="/documents"
                         class="addNewMenu_Item">
              <span style="width:15px;text-align: center;display:inline-block">
                <i class="fa-regular fa-file"></i>
              </span>
              &nbsp;
              הוספת מסמך
            </router-link>


            <div class="addNewMenu_Item">
              <span style="width:15px;text-align: center;display:inline-block">

                <i class="fa-solid fa-receipt"></i>

              </span>
              &nbsp;
              מסמך חשבונאי
              <i class="fa fa-angle-left"
                 style="float:left;position:relative;top:4px;"></i>

              <div class="addNewMenu_subMenu card"
                   style="padding-top:0;margin-right:-80px;padding-right:80px;width:140px;">

                <div class="addNewMenu card"
                     style="padding-top:0">

                  <span style="color:black"
                        v-for="taxDocumentType in $store.state.taxDocumentTypes"
                        v-bind:key="taxDocumentType.value"
                        @click="$root.$emit('openTargetForNewEntityModal', 'taxDocument', { taxDocumentType })"
                        class="addNewMenu_Item">
                    &nbsp;
                    {{ taxDocumentType.name }}
                  </span>


                </div>


              </div>

            </div>


          </div>











        </div>






        <div onclick="document.querySelector('#sidemMenuAddNewMenu').classList.add('popupMenuToHideOrNot_showMenu')"
        v-if="$store.getters.getUser.type!=1"
             id="addNewMenuButton"
             class="btn btn-primary popupMenuToHideOrNot_openerButton"
             style="display:flex;align-items: center;justify-content: center;width: 105px;margin-top: 15px;padding: 8px;box-sizing: content-box;padding-right: 5px;padding-left: 15px;">
          <i style="pointer-events:none"
             class="fa fa-plus"></i>
          <div style="pointer-events:none;margin-right:3px">
            &nbsp;
            הוספה
          </div>

        </div>


      </li>
      <li v-for="menu in PlanupMenu">



        <router-link v-if="!menu.subMenu"
                     :to="menu.route"
                     class="sidebar-header">
          <div @click="$emit('hideSidebarIfMobile')">
            {{ $t("menu_" + menu.name) }}
          </div>
        </router-link>


        <a v-if="menu.subMenu"
           class="sidebar-header"
           onclick="this.parentElement.classList.toggle('active')"
           href="javascript:void(0)">
          {{ $t("menu_" + menu.name) }}
        </a>

        <ul class="sidebar-submenu menu-open"
            v-if="menu.subMenu">

          <li v-for="subMenu in menu.subMenu">

            <router-link :to="subMenu.route">
              <div @click="$emit('hideSidebarIfMobile')">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {{ $t("menu_" + subMenu.name) }}
              </div>

            </router-link>
          </li>



        </ul>


      </li>

      <li @click="logOff" style="cursor:pointer">
        <a class="sidebar-header">
          {{ $t('logoff') }}
        </a>

      </li>

    </ul>




    <br>



  </div>
</template>

<script>
import Role from '../../core/auth/roles.enum';
import MenuForBiz from "@/core/menu/menu_for_biz";
import MenuForAdmin from "@/core/menu/menu_for_admin";

export default {
  name: "SideMenu",
  data() {
    return {}
  },


  methods: {

    logOff() {


      //yonso hardcore logoff instead:
      document.cookie = `token=; path=/;SameSite=strict; Secure;`;
      localStorage.removeItem('tenantId');
      window.location.href = `http://${window.location.host}`;


      //previous logoff

      // this.$store
      //   .dispatch("logoff")
      //   .then((response) => {
      //     this.$router.push("/login");
      //   })
      //   .catch((err) => {
      //     console.warn("Failed to logoff - ", err);
      //     this.$router.replace("/login", function () {
      //       setTimeout(function () {
      //         location.reload();
      //       }, 1000);
      //     });
      //   });
    },


  },


  computed: {





    PlanupMenu() {

      let menu = [];
      let visibleMenu = [];

      switch (this.$store.getters.getUser.type) {

        case Role.Admin:
          visibleMenu = MenuForAdmin;
          return;

        case Role.HallManager:
          menu = MenuForBiz;
          break;

      }



      let bizServices = this.$store.getters.getUser.bizServices;

      menu.forEach(menuElement => {



        //for now showing full menu for everybody
        //for now showing full menu for everybody
        //for now showing full menu for everybody
        //for now showing full menu for everybody
        //for now showing full menu for everybody



        if (true || bizServices.includes('' + menuElement.service))

          visibleMenu.push(menuElement);

      });

      return visibleMenu;
    },
  },


};
</script>
<style>
.addNewMenu_Item {
  /* border-bottom: solid 1px #dee2e6; */
  padding: 7px 8px;
  cursor: pointer;
  margin: 4px 0;
  position: relative;
  overflow: visible;
  display: block;
}

.addNewMenu_subMenu {
  position: absolute;
  top: 0;
  right: 100%;
  display: none;
  width: 155px;
  background: #f6f7fb;
}

.addNewMenu_subMenu .addNewMenu_Item {
  padding-right: 10px;
}

.addNewMenu_Item:hover .addNewMenu_subMenu {
  display: block;
  z-index: 999;

}

.addNewMenu_Item:hover {
  /* background-color: #ab8ce4; */
  background-color: #e3e3e3;
}


.addNewMenu i {
  color: rgb(119, 119, 119);
}

.addNewMenu {
  top: 0;
  right: 0;
  position: absolute;
  width: 100%;
  padding: 5px 0;
  background: #f6f7fb;
}
</style>
