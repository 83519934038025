<template>
    <Modal title="למי תרצו לשלוח זימון?"
           ref="modal"
           :hideFooter="true">

        <template v-slot:body>



            <div v-if="title">
                {{ title }}
            </div>

            <div v-if="location">
                <i class="fa-solid fa-location-dot"></i>
                &nbsp;
                {{ location }}
            </div>

            <div v-if="date">
                <i class="fa-regular fa-calendar"></i>
                &nbsp;
                {{ $globals.niceDate(date) }}

                <span v-if="time">
                    {{ time }}
                </span>
            </div>

            <br>

            <div v-for="person in people">

                <div class="mb-2">

                    <img :src="person.pic"
                         class="rounded-circle"
                         style="width: 40px;height: 40px;object-fit: cover;object-position: center;"
                         alt="">

                    {{ person.name }}

                </div>


                <div class="row mb-3">
                    <div class="col-6">

                        <label style="user-select: none;cursor:pointer"
                               class="form-label"
                               @click="person.notifyByMail = !person.notifyByMail">


                            <span style="position:relative;top:1px;"
                                  class="planup-checkbox planup-checkbox-purple"
                                  :class="{ checked: person.notifyByMail }">
                                <i class="fa fa-check"></i>
                            </span>


                            שלח זימון במייל
                        </label>


                        <input class="form-control"
                               v-model="person.email">

                        <span class="formErrorText"
                              v-if="person.errors">
                            {{ person.errors.email }}
                        </span>


                    </div>



                    <div class="col-6">

                        <label style="user-select: none;cursor:pointer"
                               class="form-label"
                               @click="person.notifyByWhatsapp = !person.notifyByWhatsapp">


                            <span style="position:relative;top:1px;"
                                  class="planup-checkbox planup-checkbox-purple"
                                  :class="{ checked: person.notifyByWhatsapp }">
                                <i class="fa fa-check"></i>
                            </span>


                            שלח זימון בואצאפ
                        </label>

                        <input class="form-control"
                               type="number"
                               v-model="person.phone">

                        <span class="formErrorText"
                              v-if="person.errors">
                            {{ person.errors.phone }}
                        </span>

                    </div>

                </div>








            </div>











            <!-- EXTRA MAILS -->
            <!-- 
            <div class="col-12"
                 v-show="meeting.isNotifyByMail">
                <div class="mb-3">
                    <label class="form-label">
                        נמענים
                    </label>



                    <Multiselect selectedLabel=""
                                 deselect-label=""
                                 label="name"
                                 track-by="email"
                                 :multiple="true"
                                 placeholder=""
                                 @tag="addNotifyMailAddress"
                                 selectLabel=""
                                 tagPlaceholder=""
                                 :taggable="true"
                                 v-model="meeting.notifyMailAddress"
                                 :options="contactMails">
                    </Multiselect>




                </div>
            </div> -->


            <div class="mb-3">

                <label class="form-label">
                    הודעה אישית


                    <span style="color:#ab8ce4;cursor:pointer"
                          @click="$refs.messageTemplatePopup.show()">
                        בחירה מתוך תבניות
                    </span>
                </label>

                <textarea class="form-control"
                          v-model="message"
                          style="height:100px;"></textarea>


                <MessageTemplatePopup @text="message = $event"
                                      ref="messageTemplatePopup"></MessageTemplatePopup>


            </div>




            <div class="btn btn-primary pull-right"
                 @click="summon()">
                שלח
            </div>

        </template>

    </Modal>
</template>
<script>

import { apiPost } from '../../core/http/axios_api_v2';
import MessageTemplatePopup from '../MessageTemplates/MessageTemplatePopup.vue';


export default {
    name: 'SummonModal',
    data() {

        return {
            people: [],
            title: '',
            location: '',
            date: null,
            time: '',
            message: ''
        }

    },

    methods: {

        //finally using params object!
        show(data) {

            this.people = [];
            this.title = data.title;
            this.location = data.location;
            this.date = data.date;
            this.time = data.time;

            for (let i = 0; i < data?.contacts?.length; i++) {
                this.people.push({
                    name: this.$handlers.customerHandler.getDisplayName(data.contacts[i]),
                    email: data.contacts[i].email,
                    phone: data.contacts[i].phone,
                    pic: this.$handlers.customerHandler.getContactImage(data.contacts[i]),
                    notifyByMail: !!data.contacts[i].email,
                    notifyByWhatsapp: !!data.contacts[i].phone,
                    errors: {
                        email: null,
                        phone: null
                    }
                });
            }

            for (let i = 0; i < data?.crewMembers?.length; i++) {
                this.people.push({
                    name: data.crewMembers[i].name,
                    email: data.crewMembers[i].email,
                    phone: data.crewMembers[i].phone,
                    pic: this.$handlers.customerHandler.getContactImage(data.crewMembers[i]),
                    notifyByMail: !!data.crewMembers[i].email,
                    notifyByWhatsapp: !!data.crewMembers[i].phone,
                    errors: {
                        email: null,
                        phone: null
                    }
                });
            }

            this.$refs.modal.show();
        },

        summon() {

            let foundError = false;

            this.people.forEach(person => {

                if (person.notifyByMail) {
                    if (!person?.email?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
                        person.errors.email = 'נא להזין מייל תקין';
                        foundError = true;
                    }
                }

                if (person.notifyByWhatsapp) {
                    if (!person?.phone?.toString().match(/^\d{9,10}$/) || person?.phone[0] != '0') {
                        person.errors.phone = 'נא להזין טלפון תקין';
                        foundError = true;
                    }
                }
            });


            if (foundError) {
                return;
            }



            this.date.setHours(this.time ? this.time?.split(':')[0] : 8);
            this.date.setMinutes(this.time ? this.time?.split(':')[1] : 0);

            let dateForCalendar = this.date.toISOString().replace(/[-:]/g, '').replace(/\.\d{3}/, 'Z');

            apiPost('summons', { message:this.message, people: this.people, title: this.title, location: this.location, date: dateForCalendar })

            this.$refs.modal.hide();


        }
    },
    components: {
        MessageTemplatePopup
    }
}
</script>