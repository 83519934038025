import { isPlanup } from "../../Config"
import { hallColor } from "../../helpers/hall/index"
import { nameByType } from '../../services/quotation/item-type-name.js';
import store from '../../store'


function convertToUTCSameTime(localDate) {


    const utcDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), localDate.getHours(), localDate.getMinutes(), localDate.getSeconds()));
    return utcDate.toISOString();
}


function createGradient(colors) {
    const step = 100 / colors.length;
    const gradient = colors.map((color, index) => {
        const start = step * index;
        const end = step * (index + 1);
        return `${color} ${start}%, ${color} ${end}%`;
    }).join(', ');

    return `linear-gradient(to right, ${gradient})`;
}

function buildCrewMembersBackground(crewMembers) {

    if (!crewMembers)
        return '';

    let colors = crewMembers.map(itemCrewMember =>
        planup.$store.state.crewMembers.find(storeCrewMember => storeCrewMember.id == itemCrewMember.id)?.color
    )

    return `background: ${createGradient(colors)}`;

}


export const mapEvents = (x) => {


    let classes = [];

    switch (x.planupSystemStatus) {

        case 'priceOfferSent':
            classes.push('calendarItemMaybeEvent');
            break;

        case 'hazmanaSent':
            classes.push('calendarItemShiryunEvent');
            break;

        case 'hazmanaSigned':
            classes.push('calendarItemEvent');
            break;



    }



    return {
        'id': `event_${x.id}`,
        title: (x.time ? (x.time + ' ') : '') + (x.name ? x.name : 'ללא שם'),
        'startDate': convertToUTCSameTime(planup.$globals.addTimeToDate(new Date(x.date), x.time)),
        'classes': classes,
        // 'style': 'background-color: #ddcff6;cursor:pointer',
        'itemType': 'event',
        'customer': x.customer,
        style: buildCrewMembersBackground(x.crewMembers),
        eventId: x.id,
        time: x.time,
        endTime: x.endTime,
        originalTitle: x.name,
    }
}


// export const mapMaybeEvents = (x) => {

//     return {
//         'id': `maybeEvent_${x.id}`,
//         title: (x.time ? (x.time+' ') : '' ) + (x.name ? x.name : 'ללא שם'),
//         'startDate':  convertToUTCSameTime(planup.$globals.addTimeToDate(new Date(x.date),x.time)),
//         'classes': ['calendarItemMaybeEvent'],
//         // 'style': 'background-color: #ddcff6;cursor:pointer',
//         'itemType': 'maybeEvent',
//         'customer': x.customer,
//         eventId: x.id,
//         time: x.time,
//         endTime:x.endTime,
//         style: buildCrewMembersBackground(x.crewMembers),
//         originalTitle: x.name,
//     }
// }


export const mapGoogleEvents = (x) => {

    return {
        title: x.name,
        startDate: new Date(x.date),
        'classes': ['calendarItemGoogleEvent'],
        // 'style': 'background-color: #ddcff6;cursor:pointer',
        'itemType': 'googleEvent',
        // 'customer': x.customer,
        // eventId: x.id,
        time: x.time,
        originalTitle: x.name,
    }
}


export const mapTasks = (x) => {

    return {
        'id': `task_${x.id}`,
        title: x.name ? x.name : 'ללא שם',
        'startDate': convertToUTCSameTime(new Date(x.date)),
        'classes': ['calendarItemTask'],
        // 'style': 'background-color: #87e4f0;cursor:pointer',
        'itemType': 'task',
        'customer': x.customer,
        taskId: x.id,
        time: x.time,
        endTime: x.endTime,
        style: buildCrewMembersBackground(x.crewMembers),
        originalTitle: x.name,
    }
}

export const mapQuotes = (x) => {

    return {
        'id': `quote_${x.id}`,
        'title': `${x.clientName} | ${nameByType(x.type)}`,
        'startDate': new Date(x.eventDate),
        'classes': ['x.type'],
        // 'style': isPlanup() ? 'background-color: #' + hallColor((x.hall.id * 10000).toString()).toString(16) : '',
        'itemType': 'quotation',
    }
}

export const mapMeetings = (x) => {


    return {
        'id': `meeting_${x.id}`,
        title: (x.time ? (x.time + ' ') : '') + (x.title ? x.title : 'ללא שם'),
        'startDate': convertToUTCSameTime(planup.$globals.addTimeToDate(new Date(x.date), x.time)),
        'classes': ['calendarItemMeeting'],
        'itemType': 'meeting',
        'customer': x.customer,
        meetingId: x.id,
        time: x.time,
        endTime: x.endTime,
        style: buildCrewMembersBackground(x.crewMembers),
        originalTitle: x.title,

    }
}

export const mapCalendarItems = (x) => {

    let prefix = x.type.replace('O', 'אופציה').replace('R', 'שריון').replace('N', '')
    return {
        'id': `item_${x.id}`,
        'title': `${prefix} - ${x.title}` || '',
        'startDate': new Date(x.start_date),
        'classes': [`item_${x.type}`],
        'itemType': `item_${x.type}`
    }
}