<!-- This component can take both from a local list, say in $store, or directly from a controller -->




<!-- fields is an array of objects:
    {
        label:'מחיר',
        name: 'price'
        isNumber (boolean)
    } -->


<template>
    <div>
        <Modal :title="title"
               :noClose="true"
               :large="true"
               ref="superQuickEditModal"
               :hideFooter="true">

            <template v-slot:body>
                לחצו על כל שדה בטבלה כדי לערוך אותו, או על כפתור המחיקה על מנת להסיר את השורה. לבסוף לחצו על כפתור "שמור
                שינויים" או ביטול.


                <br>
                <span style="font-weight: 600;">
                    השינויים יראו רק בעדכונים עתידיים - למצוא כאן טקסט שאומר שהג'ייסון כבר קיים, זה לא ישפיע על שירותים
                    שכבר
                    נלקחו

                </span>

                <br>
                <br>


                <table class="superQuickEditTable">

                    <tr style="text-align: center;">
                        <th v-for="field in fields"
                            style="font-weight:normal">
                            {{ field.label }}
                        </th>
                    </tr>

                    <tr v-for="item in localList"
                        v-if="!item.hidden">

                        <td v-for="field in fields">


                            <div v-if="field.options && field.optionsTagFunction">

                                <Multiselect @input="addToChangedList(item)"
                                             style="min-width:220px"
                                             v-model="item[field.name]"
                                             deselect-label=""
                                             :taggable="true"
                                             placeholder=""
                                             selectLabel=""
                                             @tag="(newTag) => field.optionsTagFunction(newTag, field.options, field.name, item)"
                                             track-by="value"
                                             label="name"
                                             noResult=" נמצאה התאמה"
                                             tagPlaceholder="הקישו אנטר להוספה"
                                             selectedLabel=""
                                             :allow-empty="false"
                                             :showNoOptions="false"
                                             :options="field.options.filter(x => !x.hidden)">

                                </Multiselect>

                            </div>
                            <div v-else-if="field.options && !field.optionsTagFunction">

                                <Multiselect @input="addToChangedList(item)"
                                             style="width:220px"
                                             v-model="item[field.name]"
                                             deselect-label=""
                                             placeholder=""
                                             selectLabel=""
                                             track-by="value"
                                             noResult="לא נמצאה התאמה"
                                             label="name"
                                             selectedLabel=""
                                             :allow-empty="false"
                                             :showNoOptions="false"
                                             :options="field.options.filter(x => !x.hidden)">

                                </Multiselect>

                            </div>

                            <div v-else-if="field.isColor">

                                <input type="color"
                                       v-model="item[field.name]"
                                       @change="addToChangedList(item)"
                                       style="width: 30px; height: 30px;">

                            </div>

                            <div v-else-if="field.isPic">
                                <img onerror="this.remove()"
                                     :src="'https://planup-il.s3.eu-central-1.amazonaws.com/' + item[field.name]"
                                     class="quickSuperEditPic">
                            </div>

                            <div v-else>
                                <input class="quickSuperEditTextInput form-control"
                                       :type="field.isNumber ? 'number' : 'text'"
                                       v-model="item[field.name]"
                                       @change="addToChangedList(item)"
                                       @blur="field.blurFunction ? field.blurFunction(item) && addToChangedList(item) : () => { }"
                                       placeholder="(ללא)">

                            </div>

                        </td>

                        <td style="width:46px;text-align: center;">
                            <i style="color:rgb(119, 119, 119);"
                               @click="deleteItem(item)"
                               class="fa-regular fa-trash-can redHover"></i>
                        </td>

                    </tr>

                </table>



                <span @click="addNewItemToLocalList()"
                      style="color: rgb(141, 99, 218); cursor: pointer; font-size: 25px;position: relative; top: 3px;">
                    <i class="fa-solid fa-plus"></i>
                </span>


                <br>
                <br>

                <div class="pull-right">
                    <button class="btn btn-light"
                            @click="$refs.superQuickEditModal.hide()">
                        ביטול
                    </button>
                    &nbsp;
                    <button class="btn btn-primary"
                            @click="saveChanges()">
                        שמור שינויים
                    </button>


                </div>


            </template>

        </Modal>
    </div>
</template>
<script>

import Multiselect from "vue-multiselect";
import ListsHandler from '../../modules/ListsHandler';

export default {
    name: 'QuickSuperEdit',
    props: ['title', 'listName', 'fields', 'controller', 'bulkSaveFilterFunction', 'filterListFunction'],
    data() {
        return {
            localList: [],
            changedList: [],
        }
    },

    computed: {
        // countItems() - lets us reload our list if the page loads before ajax of loading the $store.state completes
        countItems() {

            if (!this.$store.state[this.listName])
                return 0;

            return this.$store.state[this.listName].length;
        }
    },

    watch: {
        countItems() {
            this.initList();
        }
    },
    methods: {

        deleteItem(item) {

            item.hidden = true;
            this.addToChangedList(item);
        },

        addNewItemToLocalList() {
            let newItem = {};   //this is so cool, that we use the reference of it
            this.localList.push(newItem);
            this.changedList.push(newItem);
        },

        saveChanges() {

            this.$swal({
                title: 'האם לשמור את השינויים שביצעתם?',
                showCancelButton: true,
                confirmButtonText: 'שמור',
                cancelButtonText: 'ביטול',
                confirmButtonColor: '#ab8ce4',
            }).then(() => {
                ListsHandler.bulkSave(this.changedList, this.listName, this.controller, this.bulkSaveFilterFunction, () => {
                    this.$emit('updateList');
                    this.$refs.superQuickEditModal.hide();
                })
            })

        },

        addToChangedList(item) {

            if (this.changedList.indexOf(item) == -1)
                this.changedList.push(item);

        },

        initList() {


            if (this.listName) {

                this.localList = this.countItems ? deepCopy(this.$store.state[this.listName].filter(x => !x.hidden)) : [];

            }


            let filterListFunction = this.filterListFunction;


            //take from controller if we don't have list name
            if (!this.listName) {

                this.$handlers.listsHandler.getList(this.controller, (response) => {

                    this.localList = filterListFunction ? response.data.map(i => filterListFunction(i)) : response.data;

                })

            }


        },

        show() {

            this.$refs.superQuickEditModal.show();
            this.initList();    //we kill unsaved changes
        },
    },

    components: { Multiselect }
}
</script>
<style>
.superQuickEditTable {
    width: 100%
}

.superQuickEditTable td {
    padding: 5px;
}
</style>