<template>
    <div class="chatWrapper">
        <div class="chat-header">


            <div :class="typeOfChat"></div>

            <img style="border:solid 1px #eeeeee"
                 :src="$handlers.customerHandler.getContactImage(contact)">


            <div style="float:right;padding-right:10px;"
                 v-if="contact">

                <div style="color: #2a3142;letter-spacing: 1px;font-weight: 600;" class="chatname">
                    {{ $handlers.customerHandler.getDisplayName(contact) }}
                    &nbsp;
                </div>

                <div style="color: #aaaaaa;letter-spacing: 1px;font-size: 12px;margin-top: 5px;" v-if="messages.length">

                    הודעה אחרונה ב

                    {{ $globals.niceDate(messages[messages.length - 1].created) }}

                    בשעה

                    {{ $globals.niceTime(messages[messages.length - 1].created) }}

                </div>

            </div>

            <div style="clear:both"></div>

        </div>





        <div ref="messageContainer"
             class="custom-scrollbar"
             style="padding:15px;padding-top:20px;height:calc(100vh - 455px);overflow:auto;">







            <div v-for="message in messages.slice().reverse()">




                <div v-if="message.incomingFromCustomer"
                     class="chatIncommingMessage">

                    <img :src="$handlers.customerHandler.getContactImage(contact)">

                    <div class="chatIncommingMessageInnerInfo">

                        <span class="chatIncommingMessageInnerInfoTime">

                            {{ $globals.niceDate(message.created) }}

                            בשעה

                            {{ $globals.niceTime(message.created) }}

                        </span>

                    </div>


                    <div>
                        {{ message.message }}
                    </div>


                </div>

                <div v-if="!message.incomingFromCustomer"
                     class="chatOutgoingMessage">

                    <img :src="$store.state.logoImageUrl">

                    <div class="chatOutgoingMessageInnerInfo">

                        <span class="chatOutgoingMessageInnerInfoTime">

                            {{ $globals.niceDate(message.created) }}

                            בשעה

                            {{ $globals.niceTime(message.created) }}

                        </span>

                    </div>

                    <div>
                        {{ message.message }}
                    </div>

                </div>

                <div style="clear:both"></div>

            </div>

        </div>






        <div class="chat-input-wrapper">




            <div style="border:2px solid #ab8ce4;border-radius:5px;background:white;overflow:hidden;position:relative;">


                <input v-model="messageToSend"
                       @keyup.enter="sendMessage"
                       style="width:calc(100% - 100px);border:none !important;outline:none !important;padding:10px; padding-right: 15px;"
                       placeholder="כתבו הודעה או                       ...">




                    <div @click="$refs.messageTemplatePopup.show()" @text="messageToSend = $event"  style="position:absolute;top:10px;right:93px;color:#ab8ce4;cursor:pointer" v-show="!messageToSend">
בחרו תבנית
                    </div>


                <MessageTemplatePopup @text="messageToSend = $event"
                                      ref="messageTemplatePopup"></MessageTemplatePopup>



                <button class="btn btn-primary"
                        @click="sendMessage"
                        style="    border-radius: 0px;width: 100px;padding: 8px;font-size: 17px;">
                    שלח
                </button>



            </div>




        </div>

    </div>
</template>
<script>

import MessageTemplatePopup from '@/components/MessageTemplates/MessageTemplatePopup.vue';

export default {
    name: 'PlanupMultiMessagesChat',
    props: ['msgGroup'],
    data() {
        return {
            messageToSend: '',
            typeOfChat: '',
            messages: [],
            contact: {}
        }
    },
    methods: {



        scrollToBottom(){

            this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;

        },

        sendMessage() {

            this.$handlers.whatsappHandler.sendMessage(this.contact.phone, this.messageToSend);

        }
    },

    watch: {

        msgGroup: {
            immediate: true,
            handler: function (msgGroup) {

                if (!msgGroup)
                    return;

                this.$handlers.planupMultiMessagesHandler.loadConversation(msgGroup).then(data => {

                    this.messages = data.messages;
                    this.contact = data.contact;

                    // this.typeOfChat = data.messages[0].typeOfMessage;
                    this.typeOfChat = msgGroup.slice(0, 8) == 'whatsapp' ? 'whatsapp' : 'email';

                    this.$nextTick(() => {
                        this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight;
                    })

                })
            }
        },
    },
    components: { MessageTemplatePopup }


}
</script>