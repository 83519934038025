import axiosV2, { apiPost } from '../core/http/axios_api_v2';
import EventHandler from './EventHandler';

export default {




    deleteSearchResult(id){
        return apiPost(`search-results/delete/${id}`);
    },


    validateCustomerContacts(component, customer) {


        let failedValidation = false;
        let foundCustomerWithPhone = false;


        customer.contacts.map(contact => {

            if (!this.validateContact(component, contact))
                failedValidation = true;

            if (contact.phone)
                foundCustomerWithPhone = true;

        })


        if (!foundCustomerWithPhone) {
            customer.contacts[0].errors.phone = customer.contacts.length > 1 ? 'נדרש לפחות טלפון אחד ללקוח' : 'שדה חובה';
            failedValidation = true;
        }



        return !failedValidation;
    },


    // not all fields are required
    validateContact(component, contact) {




        let contactErrors = {};
        let forceOpenExtraFields = ['email', 'teudatZehut', 'address_mikud'];


        contactErrors.firstName = !contact.firstName ? 'שדה חובה' : null;
        // contactErrors.lastName = !contact.lastName ? 'שדה חובה' : null;

        contactErrors.phone = contact.phone && (!contact.phone.toString().match(/^\d{9,10}$/) || contact.phone[0] != '0') ? 'נא להזין טלפון תקין' : null;

        contactErrors.email = contact.email && !contact.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) ? 'נא להזין מייל תקין' : null;
        contactErrors.teudatZehut = contact.teudatZehut && !contact?.teudatZehut?.toString().match(/^\d{9}$/) ? 'נא להזין תעודת זהות תקינה' : null;
        contactErrors.address_mikud = contact.address_mikud && !contact.address_mikud.toString().match(/^\d{7}$/) ? 'נא להזין מיקוד תקין' : null;


        contactErrors.forceOpenExtraFields = !!forceOpenExtraFields.find(x => contactErrors[x]);

        component.$set(contact, 'errors', contactErrors);

        //this checks if there is at least one field with an error
        let contactIsValid = !Object.values(contactErrors).some(value => value);

        if (!contactIsValid)
            planup.$globals.notifyFormError();
        return contactIsValid;
    },


    validateContactForTaxDocument(component, contact) {

        let contactErrors = {};

        contactErrors.firstName = !contact.firstName ? 'שדה חובה' : null;
        // contactErrors.lastName = !contact.lastName ? 'שדה חובה' : null;
        contactErrors.phone = contact.phone && !contact.phone.toString().match(/^\d{9,10}$/) || contact.phone[0] != '0' ? 'נא להזין טלפון תקין' : null;

        contactErrors.email = contact.email && !contact.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) ? 'נא להזין מייל תקין' : null;
        contactErrors.teudatZehut = !contact.teudatZehut || !contact.teudatZehut.toString().match(/^\d{9}$/) ? 'נא להזין תעודת זהות תקינה' : null;

        contactErrors.address_mikud = contact.address_mikud && !contact.address_mikud.toString().match(/^\d{7}$/) ? 'נא להזין מיקוד תקין' : null;
        // contactErrors.address_street = !contact.address_street ? 'שדה חובה' : null;
        // contactErrors.address_number = !contact.address_number ? 'שדה חובה' : null;
        // contactErrors.address_city = !contact.address_city ? 'שדה חובה' : null;


        component.$set(contact, 'errors', contactErrors);

        //this checks if there is at least one field with an error
        let contactIsValid = !Object.values(contactErrors).some(value => value);

        if (!contactIsValid)
            planup.$globals.notifyFormError();

        return contactIsValid;



    },


    isCustomerRelevant(customer) {
        return !(customer?.planupCustomerStatus?.type?.value === 0);
    },






    planupCustomerStatusTypeOptions: [
        { name: 'לא רלוונטי', value: 0 },
        { name: 'אופציה לאירוע', value: 1 },
        { name: 'אירוע סגור', value: 2 }
    ],


    //for message, contact or crewmember
    getDisplayName(messageOrcontact) {

        switch (true) {
            case !!messageOrcontact.name:
                return messageOrcontact.name;
            case !!(messageOrcontact.firstName && messageOrcontact.lastName):
                return `${messageOrcontact.firstName} ${messageOrcontact.lastName}`
            case !!messageOrcontact.firstName:
                return messageOrcontact.firstName;
            case !!messageOrcontact.lastName:
                return messageOrcontact.lastName;
            case !!messageOrcontact.emailAddress:
                return messageOrcontact.emailAddress;
            case !!messageOrcontact.email:
                return messageOrcontact.email;
        }

        return '';
    },





    getContactData(contactId) {

        return axiosV2(`customer-contact/${contactId}`).then(res => res.data);
    },



    //for message, contact or crewmember
    getContactImage(messageOrcontact) {

        if (messageOrcontact.pic) {
            return `https://planup-il.s3.eu-central-1.amazonaws.com/${messageOrcontact.pic}`;
        }

        let a = this.getDisplayName(messageOrcontact);

        return planup.$globals.getLetterImage(this.getDisplayName(messageOrcontact))

    },



    // helper functions
    //////////////////////

    generateLocalEmptyCustomer() {
        return { contacts: [this.generateLocalEmptyContact()], leadId: null, notRelevant: false, leadSource: null, planupCustomerStatus: null }
    },

    generateLocalEmptyContact() {
        return { firstName: null, lastName: null, phone: null, email: null, teudatZehut: null, address_mikud: null, address_street: null, address_number: null, address_city: null, showInTaxDocument:true }
    },

    doesCustomerExistOnServer(customer) {
        return !!customer.id;
    },


    buildContactsSummaryForTimeline(customer) {


        let timelineSummary = '';

        for (let i = 0; i < customer.contacts.length; i++) {
            timelineSummary += customer.contacts[i].firstName + ', ';
        }

        timelineSummary = timelineSummary.slice(0, -2);


        return timelineSummary;


    },


    // leadToCustomer(leadId) {


    //     return apiPost(`/customers/leadToCustomer/${leadId}`)

    // },


    getLead(leadId) {

        return axiosV2(`/customers/getLead/${leadId}`).then(response => response.data);

    },


    // getting functions
    //////////////////////

    async getCustomer(id) {

        return axiosV2("/customers/" + id).then(

            response => {

                let customer = response.data;

                for (let i = 0; i < customer.events?.length; i++) {
                    EventHandler.prepEventFromServerToVue(customer.events[i]);
                    customer.events[i].index = i;  //adding index to events
                }

                return customer;
            }
        );
    },

    async getMinimalData(id) {

        return axiosV2("/customers/minimalData/" + id).then(

            response => response.data
        )
    },




    // saving functions
    //////////////////////


    async saveCustomerContacts(customerData, hideSuccessAlert, callback) {

        if (this.doesCustomerExistOnServer(customerData)) {

            return await this.updateContacts(customerData, callback, hideSuccessAlert);

        } else {

            return await this.createNewCustomer(customerData, callback, hideSuccessAlert);

        }
    },



    async createNewCustomer(customerData, callback, hideSuccessAlert) {

        return apiPost('/customers', customerData, hideSuccessAlert).then(
            savedCustomer => {
                customerData.id = savedCustomer.id;
                customerData.contacts = savedCustomer.contacts;
                if (callback)
                    callback();
            });
    },


    async updateContacts(customerData, callback, hideSuccessAlert) {


        return apiPost(`/customers/contacts/${customerData.id}`, customerData.contacts, hideSuccessAlert).then(x => callback ? callback(x) : null)
    },



    // navigation functions


    // goToAddCustomerOnlyContacts(){
    //     planup.$router.push(`/customer/?onlyContacts`)
    // },

    goToCustomer(customer) {
        planup.$router.push(`/customer/${customer.id}`)
    },


    goToSearchResult(searchResult) {


        if (searchResult.eventId) {

            planup.$router.push(`/customer/${searchResult.customerId}?eventId=${searchResult.eventId}`)

        } else {

            planup.$router.push(`/customer/${searchResult.customerId}`)

        }


    },



    sendMessage(customerId, messageData) {
        apiPost(`customers/sendMessage/${customerId}`, messageData);
    },

    goToAddCustomerOnlyMeeting(date,time) {

        planup.$root.$emit('hideQuickCalendar');

        if (date) {

            let url = `/customer?onlyMeeting&dateForMeeting=${date.getTime()}`;
            if (url)
                url += `&timeForMeeting=${time}`;
            
            planup.$router.push(url)
            return;
        }

        planup.$router.push(`/customer?onlyMeeting`)
    },


    goToAddCustomerOnlyTask(date) {

        planup.$root.$emit('hideQuickCalendar');

        if (date) {

            planup.$router.push(`/customer?onlyTask&dateForTask=${date.getTime()}`)
            return;
        }

        planup.$router.push(`/customer?onlyTask`)
    },




    goToAddCustomerAddEvent(date,time) {


        planup.$root.$emit('hideQuickCalendar');

        if (date) {

            let url = `/customer?dateForEvent=${date.getTime()}`
            if (time) {
                url += `&timeForEvent=${time}`
            }
            planup.$router.push(url);


            return;
        }

        planup.$router.push(`/customer`)

    },



    getCustomerEmail(customer) {

        if (!customer?.contacts?.length) {
            return '';
        }

        for (let i = 0; i < customer.contacts.length; i++) {
            if (customer?.contacts[i]?.email?.length)
                return customer.contacts[i].email;
        }

    },



    getCustomerSearchResult(customerId) {

        return (axiosV2(`/search-results/getCustomerSearchResult/${customerId}`)).then(response => response.data);
    }












}