<template>
    <div>

        <h5>
            ואצאפ
        </h5>


        <br>


        מצב חשבון:

        {{ whatsappStatus }}

        <span>

            <i v-if="!whatsappStatus"
               class="fa-solid fa-spinner fa-spin"></i>

        </span>



        <br>
        <br>


        <div class="pull-right">


            <button class="btn btn-success"
                    :disabled="!whatsappStatus || whatsappStatus != 'לא מחובר'"
                    @click="showQrModal">
                <i class="fa-brands fa-whatsapp"></i>
                &nbsp;
                חיבור חשבון

            </button>


            &nbsp;

            <button class="btn btn-danger"
                    :disabled="!whatsappStatus || whatsappStatus != 'מחובר'"
                    @click="disconnect">

                ניתוק חשבון

            </button>

        </div>





        <Modal ref="modal"
               title="חיבור Whatsapp"
               :hideFooter="true">

            <template v-slot:body>




                <div v-show="waitingForQr"
                     style="text-align: center;">

                    <br>
                    <br>

                    דברים קורים...

                    <br>
                    <br>



                    <i class="fa-solid fa-cog fa-spin"
                       style="font-size:22px"></i>
                    <br>
                    <br>
                    <br>
                    <br>



                </div>



                <div v-show="!waitingForQr && !qr">



                    <br>
                    <br>
                    <br>

                    כאן יופיע קוד
                    QR
                    לסריקה באפליקציית
                    Whatsapp
                    בטלפון הנייד.
                    <br>

                    על מנת להמשיך



                    הכינו את המכשיר במצב סריקה ואז לחצו על הכפתור "צור QR".
                    <br>
                    תהליך היצירה אורך כדקה.

                    <br>
                    <br>
                    <br>
                    <br>
                    <br>

                    <button class="btn btn-primary pull-right"
                            @click="getQr">
                        צור QR
                    </button>

                </div>





                <div v-if="qr"
                     v-html="qr">
                </div>



            </template>

        </Modal>



    </div>
</template>
<script>

export default {
    name: 'WhatsappWeb',
    data() {

        return {
            whatsappStatus: '',
            waitingForQr: false,
            qr: null,
            phone: this.suggestedPhone
        }
    },
    props: ['suggestedPhone'],
    methods: {
        showQrModal() {
            this.qr = null;
            this.$refs.modal.show();
        },
        getQr() {
            this.waitingForQr = true;
            this.$handlers.whatsappHandler.getQr().then(qrFromServer => {
                this.qr = qrFromServer
                this.waitingForQr = false;
            })
        },
        disconnect() {
            this.$handlers.whatsappHandler.disconnect();
        }
    },
    mounted() {

        this.$handlers.whatsappHandler.getStatus().then(status => {
            this.whatsappStatus = status;


            console.log('whatsapp status:', status);
        })
    }

}
</script>